@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
}

body {
  font-family: "Roboto", sans-serif;
}
body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
body .popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: -3px -1px 20px 10000px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  margin: 0 20px;
  max-width: 550px;
  height: 300px;
  border-radius: 20px;
  z-index: 9999;
}
body .popup-content {
  font-size: 25px;
  text-align: center;
  padding: 10px;
}
body .Header_page {
  height: 80px;
  width: 100%;
  z-index: 1000;
  position: absolute;
}
body .Header_page .nav_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
body .Header_page .nav_wrapper .logo_div {
  margin-right: 20px;
}
body .Header_page .nav_wrapper .logo_div img {
  max-height: 40px;
}
body .Header_page .nav_wrapper .nav_links .nav_list {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
}
body .Header_page .nav_wrapper .nav_links .nav_list .nav_link {
  text-decoration: none;
  color: #fff;
}
body .Header_page .nav_wrapper .nav_links .nav_list .nav_link li {
  margin: 0 15px;
}
body .Header_page .nav_wrapper .nav_links .nav_list .nav_link li:hover {
  opacity: 0.8;
}
body .Header_page .nav_cta .mobile_menu {
  display: none;
}
body .Header_page .nav_cta button.navbtn {
  background-color: #550df4;
  border: none;
  font-size: 15px;
  color: white;
  border-radius: 50px;
  padding: 8px 45px;
}
body .Header_page .nav_cta button.navbtn:hover {
  opacity: 0.8;
}
body .conversion_catalyst_page {
  background-color: #161616 !important;
}
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .sub-title {
  border: 1px solid grey;
  border-radius: 50px;
  max-width: 300px;
  margin: 3px auto 25px;
  background-color: transparent;
  padding: 6px 17px;
}
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .sub-title p {
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 0;
}
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .hero_title {
  font-weight: 600 !important;
  max-width: 830px !important;
}
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .hero_title .purple_word {
  color: #550df4;
}
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .button-caption {
  font-size: 13px;
  font-weight: 200px;
  margin-top: 5px;
}
body .conversion_catalyst_page .home_page_wrapper .vsl {
  padding: 50px 0;
}
body .conversion_catalyst_page .home_page_wrapper .vsl .static_vsl img {
  border-radius: 12px;
  width: 100%;
}
body .conversion_catalyst_page .home_page_wrapper .video_testimonials {
  background-color: white;
  padding: 70px 0;
}
body .conversion_catalyst_page .home_page_wrapper .video_testimonials .services_content {
  margin: 0 auto 60px;
}
body .conversion_catalyst_page .home_page_wrapper .video_testimonials .services_content .services_title {
  max-width: 430px;
  font-size: 44px;
  margin: auto;
  text-align: center;
}
body .conversion_catalyst_page .home_page_wrapper .video_testimonials .services_content .services_title .purple_color {
  color: #550df4;
}
body .conversion_catalyst_page .home_page_wrapper .video_testimonials .videotestimonial_card {
  background: url("..//assets//images//grid2.svg"), #e2e6ec;
  border-radius: 20px;
  box-shadow: 0px 1px 14px 0px rgba(123, 121, 121, 0.53);
  -webkit-box-shadow: 0px 1px 14px 0px rgba(123, 121, 121, 0.53);
  -moz-box-shadow: 0px 1px 14px 0px rgba(123, 121, 121, 0.53);
  padding: 40px 15px;
}
body .conversion_catalyst_page .home_page_wrapper .text {
  text-align: center;
}
body .conversion_catalyst_page .home_page_wrapper .text .text_testimonial {
  max-width: 424px;
  margin: 0 auto 30px;
  font-size: 18px;
  font-weight: 500;
}
body .conversion_catalyst_page .home_page_wrapper .text .text_box {
  background-color: white;
  border-radius: 10px;
  margin: auto;
  width: 241px;
  padding: 9px;
}
body .conversion_catalyst_page .home_page_wrapper .text .text_box .text_speaker {
  margin-bottom: 0;
  font-weight: 500;
}
body .conversion_catalyst_page .home_page_wrapper .text .text_box .text_speaker_position {
  font-size: small;
  margin-bottom: 0;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions {
  padding: 70px 0 0;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions .services_content {
  text-align: center;
  margin-bottom: 20px;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions .services_content .services_title {
  margin: auto;
  color: white;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions .services_content .services_title .purple_color {
  color: #550df4;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions .growth-accelerator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100%;
}
body .conversion_catalyst_page .home_page_wrapper .funnelQuestions .growth-accelerator .growth-card {
  box-shadow: none !important;
}
body .conversion_catalyst_page .home_page_wrapper .book_a_call {
  padding: 70px 20px 40px;
}
body .conversion_catalyst_page .home_page_wrapper .book_a_call .services_content {
  text-align: center;
  margin-bottom: 20px;
}
body .conversion_catalyst_page .home_page_wrapper .book_a_call .services_content .services_title {
  margin: auto;
  color: white;
}
body .conversion_catalyst_page .home_page_wrapper .book_a_call .services_content .services_title .purple_color {
  color: #550df4;
}
body .conversion_catalyst_page .home_page_wrapper .book_a_call .calendly {
  min-width: 320px;
  height: 700px;
}
body #home_page,
body .conversion_catalyst_page {
  background-color: #161616 !important;
  height: 100vh;
}
body #home_page .home_page_wrapper,
body .conversion_catalyst_page .home_page_wrapper {
  background-image: radial-gradient(circle at 50% -1%, rgba(30, 4, 88, 0.55), #000 7%);
}
body #home_page .home_page_wrapper .hero_section,
body .conversion_catalyst_page .home_page_wrapper .hero_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  height: 100vh;
}
body #home_page .home_page_wrapper .hero_section .hero_content,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content {
  padding-top: 70px;
}
body #home_page .home_page_wrapper .hero_section .hero_content .hero_title,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .hero_title {
  font-weight: 500;
  font-size: 70px;
  max-width: 650px;
  margin: auto;
}
body #home_page .home_page_wrapper .hero_section .hero_content .hero_description,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .hero_description {
  color: #fcfdff;
  max-width: 650px;
  font-size: 18px;
  font-weight: 300;
  padding: 40px 10px;
  margin: auto;
}
body #home_page .home_page_wrapper .hero_section .hero_content .herobtn,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .herobtn {
  padding: 5.5px 4px 5.5px 18px !important;
}
body #home_page .home_page_wrapper .hero_section .hero_content .herobtn:hover img,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .herobtn:hover img {
  transform: rotate(90deg);
}
body #home_page .home_page_wrapper .hero_section .hero_content .herobtn img,
body .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .herobtn img {
  margin-left: 10px;
  width: 33px;
  transition: transform 200ms ease-in;
}
body #home_page .home_page_wrapper .full_line,
body .conversion_catalyst_page .home_page_wrapper .full_line {
  background: radial-gradient(48000.02% 50% at 50% 50%, #02c6c3 0%, #0f0039 33%, rgba(0, 107, 255, 0) 100%);
  height: 4px;
  width: 100%;
}
body #home_page .home_page_wrapper .half_line,
body .conversion_catalyst_page .home_page_wrapper .half_line {
  background: url("..//assets/images/halfline.png");
  height: 2px;
  width: 50%;
  margin: auto;
}
body #home_page .home_page_wrapper .call_to_action_div,
body .conversion_catalyst_page .home_page_wrapper .call_to_action_div {
  padding: 30px 0;
}
body #home_page .home_page_wrapper .call_to_action_div .cta_question,
body .conversion_catalyst_page .home_page_wrapper .call_to_action_div .cta_question {
  font-size: 38px;
  font-weight: 500;
  color: white;
  margin: auto;
}
body #home_page .home_page_wrapper .call_to_action_div .waitlistcta,
body .conversion_catalyst_page .home_page_wrapper .call_to_action_div .waitlistcta {
  margin: auto;
  text-align: right;
}
body #home_page .home_page_wrapper .call_to_action_div .waitlistcta .cta_divbtn,
body .conversion_catalyst_page .home_page_wrapper .call_to_action_div .waitlistcta .cta_divbtn {
  padding: 7px 22px;
}
body #home_page .home_page_wrapper .cta,
body .conversion_catalyst_page .home_page_wrapper .cta {
  background-color: #550df4;
  border: none;
  font-size: 16px;
  color: white;
  border-radius: 50px;
  padding: 6px 14px;
}
body #home_page .home_page_wrapper .cta:hover,
body .conversion_catalyst_page .home_page_wrapper .cta:hover {
  opacity: 0.8;
}
body #home_page .home_page_wrapper .services,
body .conversion_catalyst_page .home_page_wrapper .services {
  padding: 80px 0 100px;
}
body #home_page .home_page_wrapper .services .services_content,
body .conversion_catalyst_page .home_page_wrapper .services .services_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .services .services_content .services_title,
body .conversion_catalyst_page .home_page_wrapper .services .services_content .services_title {
  font-size: 44px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .services .services_content .services_subtitle,
body .conversion_catalyst_page .home_page_wrapper .services .services_content .services_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .services .service_card,
body .conversion_catalyst_page .home_page_wrapper .services .service_card {
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  height: 280px;
  max-width: 310px;
  margin: auto;
  padding: 30px 15px;
  box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
}
body #home_page .home_page_wrapper .services .service_card .service_icon,
body .conversion_catalyst_page .home_page_wrapper .services .service_card .service_icon {
  width: 40px;
}
body #home_page .home_page_wrapper .services .service_card .service_name,
body .conversion_catalyst_page .home_page_wrapper .services .service_card .service_name {
  color: white;
  margin-top: 14px;
}
body #home_page .home_page_wrapper .services .service_card .service_description,
body .conversion_catalyst_page .home_page_wrapper .services .service_card .service_description {
  color: rgb(177, 177, 177);
  max-width: 300px;
  margin: 16px auto 0;
}
body #home_page .home_page_wrapper .work_process,
body .conversion_catalyst_page .home_page_wrapper .work_process {
  padding: 70px 0;
  text-align: center;
}
body #home_page .home_page_wrapper .work_process .work_process_title,
body .conversion_catalyst_page .home_page_wrapper .work_process .work_process_title {
  margin-bottom: 0;
  font-size: 44px;
  color: white;
}
body #home_page .home_page_wrapper .work_process .work_process_subtitle,
body .conversion_catalyst_page .home_page_wrapper .work_process .work_process_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .work_process .process,
body .conversion_catalyst_page .home_page_wrapper .work_process .process {
  margin-top: 70px;
}
body #home_page .home_page_wrapper .work_process .process .process_line,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_line {
  align-items: center;
  text-align: center;
  width: 0.125rem;
  height: 99%;
  z-index: 1;
  right: 50%;
  border: 0.0625rem solid #fff;
  display: flex;
  justify-content: center;
  position: absolute;
}
body #home_page .home_page_wrapper .work_process .process .process_card,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 100px;
  z-index: 100;
  position: relative;
}
body #home_page .home_page_wrapper .work_process .process .process_card .details_wrapper .process_name,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card .details_wrapper .process_name {
  display: none;
}
body #home_page .home_page_wrapper .work_process .process .process_card .process_name,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card .process_name {
  color: #cde0ff;
  width: 100%;
  font-size: 26px;
  max-width: 300px;
  text-align: center;
}
body #home_page .home_page_wrapper .work_process .process .process_card .process_number_wrapper,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card .process_number_wrapper {
  background-color: #fff;
  padding: 10px 14px;
  box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
  border-radius: 6px;
}
body #home_page .home_page_wrapper .work_process .process .process_card .process_number_wrapper .process_number,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card .process_number_wrapper .process_number {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
body #home_page .home_page_wrapper .work_process .process .process_card .process_description,
body .conversion_catalyst_page .home_page_wrapper .work_process .process .process_card .process_description {
  max-width: 300px;
  font-size: 18px;
  font-weight: 300;
  color: rgb(177, 177, 177);
}
body #home_page .home_page_wrapper .packages_div,
body .conversion_catalyst_page .home_page_wrapper .packages_div {
  padding: 70px 0;
}
body #home_page .home_page_wrapper .packages_div .packages_content,
body .conversion_catalyst_page .home_page_wrapper .packages_div .packages_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .packages_div .packages_content .packages_title,
body .conversion_catalyst_page .home_page_wrapper .packages_div .packages_content .packages_title {
  font-size: 44px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .packages_div .packages_content .packages_subtitle,
body .conversion_catalyst_page .home_page_wrapper .packages_div .packages_content .packages_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .packages_div .package_card,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card {
  border: 1px solid grey;
  border-radius: 10px;
  max-width: 320px;
  margin: auto;
  padding: 13px 15px 20px;
  box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
}
body #home_page .home_page_wrapper .packages_div .package_card .package_card_header,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .package_card_header {
  text-align: center;
  background-color: #f9f9f9;
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
body #home_page .home_page_wrapper .packages_div .package_card .package_card_header .package_title,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .package_card_header .package_title {
  color: black;
  font-size: 19px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .packages_div .package_card .package_card_header .package_title.customized,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .package_card_header .package_title.customized {
  color: white;
}
body #home_page .home_page_wrapper .packages_div .package_card .package_card_header .package_subtitle,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .package_card_header .package_subtitle {
  color: rgb(107, 107, 107);
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .packages_div .package_card .package_card_header .package_subtitle.customized_p,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .package_card_header .package_subtitle.customized_p {
  color: rgb(177, 177, 177);
}
body #home_page .home_page_wrapper .packages_div .package_card .packags_card_body .package_list,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .packags_card_body .package_list {
  margin-left: 0;
  padding-left: 0;
}
body #home_page .home_page_wrapper .packages_div .package_card .packags_card_body .package_list ol li,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .packags_card_body .package_list ol li {
  margin-bottom: 10px;
  color: rgb(177, 177, 177);
}
body #home_page .home_page_wrapper .packages_div .package_card .packages_card_footer,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .packages_card_footer {
  text-align: center;
  margin-top: 25px;
}
body #home_page .home_page_wrapper .packages_div .package_card .packages_card_footer .reqBtn,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .packages_card_footer .reqBtn {
  width: 100%;
  position: relative;
}
body #home_page .home_page_wrapper .packages_div .package_card .packages_card_footer .reqBtn img,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card .packages_card_footer .reqBtn img {
  width: 30px;
  position: absolute;
  right: 3px;
  top: 3px;
}
body #home_page .home_page_wrapper .packages_div .package_card.customized_package,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package {
  margin-top: 30px;
  max-width: 1082px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body #home_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header {
  text-align: left;
  margin-bottom: 0;
  background-color: transparent;
}
body #home_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_title,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_title {
  font-size: 25px;
}
body #home_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_subtitle,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_subtitle {
  font-size: 15px;
  margin-top: 4px;
}
body #home_page .home_page_wrapper .packages_div .package_card.customized_package .reqBtn,
body .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .reqBtn {
  padding: 7px 55px;
}
body #home_page .home_page_wrapper .projects_div,
body .conversion_catalyst_page .home_page_wrapper .projects_div {
  padding: 70px 0;
}
body #home_page .home_page_wrapper .projects_div .projects_content,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .projects_div .projects_content .projects_title,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_content .projects_title {
  font-size: 44px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .projects_div .projects_content .projects_subtitle,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_content .projects_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper {
  margin: 40px 0;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev,
body #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next {
  color: #cde0ff;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev:after,
body #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next:after,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev:after,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next:after {
  font-size: 20px;
  font-weight: 900;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_image,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_image {
  float: right;
  max-width: 451px;
  width: 100%;
  height: 100%;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_image img,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_image img {
  width: 100%;
  height: 100%;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_description,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description {
  margin-top: 10px;
  margin-left: 30px;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_name,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_name {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_description .about_project_description,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description .about_project_description {
  font-size: 16px;
  max-width: 450px;
  color: rgb(177, 177, 177);
  margin: 20px 0;
  font-weight: 300;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_link,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_link {
  border-radius: 50px;
  padding: 2.5px 4px 2.5px 18px !important;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_link img,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description .project_link img {
  transform: rotate(-28deg);
  margin-left: 10px;
  width: 33px;
}
body #home_page .home_page_wrapper .projects_div .projects_swiper .mySlides,
body .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .mySlides {
  display: none;
}
body #home_page .home_page_wrapper .testimonials_div,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div {
  padding: 70px 0;
}
body #home_page .home_page_wrapper .testimonials_div .testimonials_content,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonials_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .testimonials_div .testimonials_content .testimonials_title,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonials_content .testimonials_title {
  font-size: 44px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .testimonials_div .testimonials_content .testimonials_subtitle,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonials_content .testimonials_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card {
  border: 1px solid grey;
  border-radius: 10px;
  height: 380px;
  max-width: 320px;
  margin: auto;
  padding: 33px 15px 20px;
  box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_card_content .testimonial_card_title,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_card_content .testimonial_card_title {
  color: white;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_card_content .testimonial_card_text,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_card_content .testimonial_card_text {
  padding-top: 15px;
  color: rgb(177, 177, 177);
  font-size: 15px;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_img,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .testimonial_img {
  width: 100%;
  max-width: 58px;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info {
  margin-top: 20px;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info .client_name,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info .client_name {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info .client_position,
body .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card .closing_info .client_position {
  color: grey;
  font-size: 14px;
}
body #home_page .home_page_wrapper .blogs_div,
body .conversion_catalyst_page .home_page_wrapper .blogs_div {
  padding: 70px 0;
}
body #home_page .home_page_wrapper .blogs_div .blogs_content,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blogs_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .blogs_div .blogs_content .blogs_title,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blogs_content .blogs_title {
  font-size: 44px;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .blogs_div .blogs_content .blogs_subtitle,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blogs_content .blogs_subtitle {
  color: grey;
}
body #home_page .home_page_wrapper .blogs_div .blog_card,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card {
  border: 1px solid grey;
  border-radius: 10px;
  max-width: 320px;
  margin: auto;
  padding: 15px;
  box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
}
body #home_page .home_page_wrapper .blogs_div .blog_card .blog_thumbnail,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card .blog_thumbnail {
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
body #home_page .home_page_wrapper .blogs_div .blog_card .blog_title,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card .blog_title {
  color: rgb(230, 230, 230);
  margin-top: 20px;
  font-size: 21px;
  line-height: 1.1;
}
body #home_page .home_page_wrapper .blogs_div .blog_card .blog_info,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card .blog_info {
  margin-top: 36px;
}
body #home_page .home_page_wrapper .blogs_div .blog_card .blog_info .blog_author,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card .blog_info .blog_author {
  font-size: 15px;
  color: #d6d6d6;
  margin-bottom: 0;
}
body #home_page .home_page_wrapper .blogs_div .blog_card .blog_info .blog_date,
body .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card .blog_info .blog_date {
  font-size: 14px;
  color: grey;
  margin-bottom: 10px;
}
body #home_page .home_page_wrapper .faq_div,
body .conversion_catalyst_page .home_page_wrapper .faq_div {
  padding: 70px 0;
}
body #home_page .home_page_wrapper .faq_div .faq_content,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_content {
  text-align: center;
  color: white;
  margin-bottom: 40px;
}
body #home_page .home_page_wrapper .faq_div .faq_content .faq_title,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_content .faq_title {
  max-width: 340px;
  margin: auto;
  font-size: 44px;
  margin-bottom: 10px !important;
  line-height: 1;
}
body #home_page .home_page_wrapper .faq_div .faq_content .faq_subtitle,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_content .faq_subtitle {
  max-width: 450px;
  margin: auto;
  color: grey;
}
body #home_page .home_page_wrapper .faq_div .faq_card,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  margin: 5px auto 26px;
  padding: 22px 15px;
  box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
  -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
}
body #home_page .home_page_wrapper .faq_div .faq_card .faq_header,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card .faq_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body #home_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_header_title,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_header_title {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 19px;
  color: rgb(250, 250, 250);
}
body #home_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_arrow,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_arrow {
  width: 32px;
}
body #home_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_arrow.open,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card .faq_header .faq_arrow.open {
  transform: rotate(180deg);
}
body #home_page .home_page_wrapper .faq_div .faq_card .faq_answer,
body .conversion_catalyst_page .home_page_wrapper .faq_div .faq_card .faq_answer {
  margin-top: 20px;
  color: #c7c7c7;
}
body #home_page .home_page_wrapper #footer,
body .conversion_catalyst_page .home_page_wrapper #footer {
  padding: 26px 0 0px;
}
body #home_page .home_page_wrapper #footer .footer_wrapper,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_wrapper {
  display: flex;
  justify-content: space-between;
}
body #home_page .home_page_wrapper #footer .footer_slogan,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_slogan {
  text-align: center;
  color: white;
  font-size: 24px;
  margin-bottom: 50px;
}
body #home_page .home_page_wrapper #footer .logo_div_footer img,
body .conversion_catalyst_page .home_page_wrapper #footer .logo_div_footer img {
  height: 35px;
}
body #home_page .home_page_wrapper #footer .footer_links .footer_list,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_links .footer_list {
  display: flex;
  align-items: center;
  list-style: none;
}
body #home_page .home_page_wrapper #footer .footer_links .footer_list .link_item,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_links .footer_list .link_item {
  color: rgb(211, 211, 211);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
body #home_page .home_page_wrapper #footer .footer_links .footer_list .link_item li,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_links .footer_list .link_item li {
  margin-right: 45px;
}
body #home_page .home_page_wrapper #footer .footer_cta .footerbtn,
body .conversion_catalyst_page .home_page_wrapper #footer .footer_cta .footerbtn {
  padding: 5px 30px;
}
body #home_page .home_page_wrapper #footer .social_icons,
body .conversion_catalyst_page .home_page_wrapper #footer .social_icons {
  text-align: center;
  margin: 40px auto 30px;
}
body #home_page .home_page_wrapper #footer .social_icons a,
body .conversion_catalyst_page .home_page_wrapper #footer .social_icons a {
  margin-right: 20px;
}
body #home_page .home_page_wrapper #footer .rights,
body .conversion_catalyst_page .home_page_wrapper #footer .rights {
  background-color: #060606;
  text-align: center;
  padding: 9px;
}
body #home_page .home_page_wrapper #footer .rights .copyrights,
body .conversion_catalyst_page .home_page_wrapper #footer .rights .copyrights {
  font-size: 13px;
  color: grey;
  margin-bottom: 0;
}
body #newsletter_page {
  background-color: #000;
  height: 100vh;
  position: relative;
}
body #newsletter_page .thank-you-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px !important;
  text-align: center;
  padding: 40px 20px !important;
  background: #ffffff;
  border-radius: 8px;
  margin: 20px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body #newsletter_page .thank-you-popup .thank-you-btn {
  background-color: #550df4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}
body #newsletter_page .thank-you-popup .thank-you-btn:hover {
  opacity: 0.7;
}
body #newsletter_page .newsletter_wrapper {
  background-image: radial-gradient(circle at 50% -1%, rgba(30, 4, 88, 0.55), #000 7%);
  height: 100vh;
}
body #newsletter_page .newsletter_wrapper .newsletter_logo {
  text-align: center;
  padding: 23px 0 30px;
}
body #newsletter_page .newsletter_wrapper .newsletter_logo img {
  height: 40px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px 30px;
  text-align: center;
  margin: 40px auto;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_title {
  color: #15171d;
  font-size: 40px;
  margin: 0px auto 20px;
  line-height: 1.1;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_description {
  margin-bottom: 30px;
  font-size: 15px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form {
  text-align: left !important;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form {
  flex-direction: column;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form input {
  padding: 8px 14px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form input::-moz-placeholder {
  font-size: 13px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form input::placeholder {
  font-size: 13px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form .subscribeBtn {
  background-color: #550df4;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  padding: 8px 14px;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .newsletter_Form form .subscribeBtn:hover {
  opacity: 0.8;
}
body #newsletter_page .newsletter_wrapper .page_content .page_form .form_note {
  text-align: center;
  font-size: 14px;
  margin: 20px auto 10px;
  color: #494949;
}
body #newsletter_page .newsletter_footer {
  background-color: #060606;
  text-align: center;
  width: 100%;
  padding: 9px;
}
body #newsletter_page .newsletter_footer .copyrights {
  font-size: 13px;
  color: grey;
  margin-bottom: 0;
}
body .growth-accelerator {
  background-color: #000;
  height: 100vh;
  overflow-y: hidden;
}
body .growth-accelerator ul {
  padding-left: 0;
  margin-bottom: 0;
}
body .growth-accelerator .growth_logo {
  text-align: center;
  padding: 23px 0 30px;
}
body .growth-accelerator .growth_logo img {
  height: 40px;
}
body .growth-accelerator .growth-accelerator-title {
  text-align: center;
  color: white;
  padding: 40px 0 20px;
  font-size: 30px;
  font-weight: 800;
}
body .growth-accelerator .growth-accelerator-title span {
  color: #550df4;
}
body .growth-accelerator .growth-card {
  width: 100%;
  box-shadow: 0 10px 50px 16.6666666667px rgba(139, 139, 139, 0.12);
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 104px;
  margin-top: 30px;
}
body .growth-accelerator .growth-card .card-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0;
}
body .growth-accelerator .growth-card .card-content .card_question {
  text-align: center;
}
body .growth-accelerator .growth-card .card-content .card-list {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  cursor: pointer;
}
body .growth-accelerator .growth-card .card-content .card-list li {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 13px 20px;
  flex-basis: 45.3%;
  max-width: 300px;
  display: flex;
  font-size: 17px;
  align-items: center;
  margin-top: 32px;
}
body .growth-accelerator .growth-card .card-content .card-list li.selected-answer .radioBtn {
  background-color: #550df4;
}
body .growth-accelerator .growth-card .card-content .card-list li.index-zero {
  flex-basis: 63.3%;
}
body .growth-accelerator .growth-card .card-content .card-list li .radioBtn {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50px;
  border: 1px solid #550df4;
}
body .growth-accelerator .growth-card .card-content .text_area {
  width: 85%;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
body .growth-accelerator .growth-card .card-content .text_area::-moz-placeholder {
  font-weight: 300;
}
body .growth-accelerator .growth-card .card-content .text_area::placeholder {
  font-weight: 300;
}
body .growth-accelerator .growth-card .card-content .email_field {
  width: 100%;
  margin-top: 20px;
  padding: 8px 15px;
  border: 1px solid gray;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 300px;
}
body .growth-accelerator .growth-card .card-content .email_field::-moz-placeholder {
  font-weight: 300;
}
body .growth-accelerator .growth-card .card-content .email_field::placeholder {
  font-weight: 300;
}
body .growth-accelerator .growth-card .card-content .card-bottom {
  width: 100%;
  display: flex;
  margin-top: 45px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  background-color: #550df4;
}
body .growth-accelerator .growth-card .card-content .card-bottom .cardBtn {
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
}
body .growth-accelerator .growth-card .card-content .card-bottom .cardBtn:disabled {
  opacity: 0.5;
}
body .growth-accelerator .growth-card .card-content .card-bottom .cardBtn.nextBtn {
  background: url("..//assets/images/right-arrow.png");
  background-position: 90% center;
  background-size: 20%;
  background-repeat: no-repeat;
  margin-right: 17px;
  max-width: 105px;
  width: 100%;
}
body .growth-accelerator .growth-card .card-content .card-bottom .cardBtn.prevBtn {
  background: url("..//assets/images/left-arrow.png");
  background-position: -1% center;
  background-size: 20%;
  background-repeat: no-repeat;
  max-width: 117px;
  width: 100%;
  margin-left: 23px;
}
body .contactUs_page .contactus_header {
  background-color: #000;
  height: 50vh;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .contactUs_page .contactus_header .contactUs_title {
  color: #550df4;
  font-size: 40px;
  font-weight: 500;
}
body .contactUs_page .contact-us {
  padding: 50px 0;
}
body .contactUs_page .contact-us .contact-us-wrapper {
  margin: auto;
  box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
  -webkit-box-shadow: 9px 15px 20px 0px rgba(24, 0, 77, 0.75);
  -moz-box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
  border-radius: 10px;
  padding: 40px 10px;
}
body .contactUs_page .contact-us .sendBtn {
  background-color: #550df4;
  border: none;
  font-size: 17px;
  text-transform: uppercase;
  color: white;
  border-radius: 7px;
  padding: 6px 14px;
  width: 100%;
}
body .contactUs_page .contact-us .sendBtn:hover {
  opacity: 0.8;
}
body .free-review-page {
  background-color: #000;
  padding-bottom: 30px;
}
body .free-review-page .free-review-wrapper {
  background-image: radial-gradient(circle at 50% -1%, rgba(30, 4, 88, 0.55), #000 7%);
}
body .free-review-page .free-review-wrapper .review-page-logo {
  text-align: center;
  padding: 23px 0 30px;
}
body .free-review-page .free-review-wrapper .review-page-logo img {
  height: 40px;
}
body .free-review-page .free-review-wrapper .thank-you-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px !important;
  text-align: center;
  height: 100vh;
  padding: 40px 20px !important;
}
body .free-review-page .free-review-wrapper .thank-you-popup .thank-you-btn {
  background-color: #550df4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}
body .free-review-page .free-review-wrapper .thank-you-popup .thank-you-btn:hover {
  opacity: 0.7;
}
body .free-review-page .free-review-wrapper .audit-popup,
body .free-review-page .free-review-wrapper .thank-you-popup {
  background: #ffffff;
  border-radius: 8px;
  max-width: 900px;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-section-description,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-section-description {
  margin-bottom: 20px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-section-description h2,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-section-description h2 {
  margin-bottom: 14px;
  font-size: 33px;
  font-weight: 600;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-section-description p,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-section-description p {
  font-size: 15px;
  margin: 10px 0;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-group,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-group {
  margin-bottom: 15px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-group input,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-group input:focus,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-group input:focus {
  border-color: #6c63ff;
  outline: none;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-group input.is-invalid,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-group input.is-invalid {
  border-color: red;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .form-group .invalid-feedback,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .form-group .invalid-feedback {
  color: red;
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .btn,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .btn {
  background-color: #1f0065;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .btn:hover,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .btn:hover {
  opacity: 0.7;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .note,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .note {
  margin-top: 15px;
  font-size: 12px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .close-icon,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
  font-size: 18px;
}
body .free-review-page .free-review-wrapper .audit-popup .form-section .close-icon:hover,
body .free-review-page .free-review-wrapper .thank-you-popup .form-section .close-icon:hover {
  color: #6c63ff;
}
body .free-review-page .free-review-wrapper .audit-popup .image-section,
body .free-review-page .free-review-wrapper .thank-you-popup .image-section {
  background-color: #1f0065;
  padding: 20px;
  border-radius: 8px;
  margin-right: 15px;
  width: 400px;
}
body .free-review-page .free-review-wrapper .audit-popup .image-section img,
body .free-review-page .free-review-wrapper .thank-you-popup .image-section img {
  border-radius: 8px;
}
body .popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
body .popup-container.hide {
  display: none;
}
body .popup-container .thank-you-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px !important;
  text-align: center;
  padding: 40px 20px !important;
}
body .popup-container .thank-you-popup .thank-you-btn {
  background-color: #550df4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}
body .popup-container .thank-you-popup .thank-you-btn:hover {
  opacity: 0.7;
}
body .popup-container .audit-popup,
body .popup-container .thank-you-popup {
  background: #ffffff;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
body .popup-container .audit-popup .form-section,
body .popup-container .thank-you-popup .form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
body .popup-container .audit-popup .form-section .form-section-description,
body .popup-container .thank-you-popup .form-section .form-section-description {
  margin-bottom: 20px;
}
body .popup-container .audit-popup .form-section .form-section-description h2,
body .popup-container .thank-you-popup .form-section .form-section-description h2 {
  margin-bottom: 14px;
  font-size: 33px;
  font-weight: 600;
}
body .popup-container .audit-popup .form-section .form-section-description p,
body .popup-container .thank-you-popup .form-section .form-section-description p {
  font-size: 15px;
  margin: 10px 0;
}
body .popup-container .audit-popup .form-section .form-group,
body .popup-container .thank-you-popup .form-section .form-group {
  margin-bottom: 15px;
}
body .popup-container .audit-popup .form-section .form-group input,
body .popup-container .thank-you-popup .form-section .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
body .popup-container .audit-popup .form-section .form-group input:focus,
body .popup-container .thank-you-popup .form-section .form-group input:focus {
  border-color: #6c63ff;
  outline: none;
}
body .popup-container .audit-popup .form-section .form-group input.is-invalid,
body .popup-container .thank-you-popup .form-section .form-group input.is-invalid {
  border-color: red;
}
body .popup-container .audit-popup .form-section .form-group .invalid-feedback,
body .popup-container .thank-you-popup .form-section .form-group .invalid-feedback {
  color: red;
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
}
body .popup-container .audit-popup .form-section .btn,
body .popup-container .thank-you-popup .form-section .btn {
  background-color: #0f0039;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
}
body .popup-container .audit-popup .form-section .btn:hover,
body .popup-container .thank-you-popup .form-section .btn:hover {
  opacity: 0.7;
}
body .popup-container .audit-popup .form-section .note,
body .popup-container .thank-you-popup .form-section .note {
  margin-top: 15px;
  font-size: 12px;
}
body .popup-container .audit-popup .form-section .close-icon,
body .popup-container .thank-you-popup .form-section .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
  font-size: 18px;
}
body .popup-container .audit-popup .form-section .close-icon:hover,
body .popup-container .thank-you-popup .form-section .close-icon:hover {
  color: #6c63ff;
}
body .popup-container .audit-popup .image-section,
body .popup-container .thank-you-popup .image-section {
  background-color: #0f0039;
  padding: 20px;
  border-radius: 8px;
  margin-right: 15px;
  width: 400px;
}
body .popup-container .audit-popup .image-section img,
body .popup-container .thank-you-popup .image-section img {
  border-radius: 8px;
}

@media (max-width: 1049px) {
  body .popup-container .audit-popup .form-section {
    padding: 35px 25px !important;
  }
  body .popup-container .audit-popup .image-section {
    display: none !important;
  }
}
@media (min-width: 440px) and (max-width: 539px) {
  .work_process .process_line {
    left: 3.4625rem !important;
  }
}
@media (max-width: 420px) {
  .work_process .process_line {
    height: 78% !important;
    top: 0px;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .work_process .process_line {
    left: 5.3625rem !important;
  }
}
@media (max-width: 768px) {
  .work_process .process {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative;
  }
  .work_process .process .process_card {
    justify-content: unset !important;
  }
  .work_process .process_line {
    left: 1.5625rem;
    height: 92%;
    right: 0 !important;
  }
  .work_process .details_wrapper {
    text-align: left;
    margin-left: 41px;
  }
  .work_process .details_wrapper .process_name {
    text-align: left !important;
    display: block !important;
  }
  .work_process .process_name {
    display: none;
  }
}
@media (max-width: 990px) {
  body .free-review-page .free-review-wrapper .audit-popup {
    flex-direction: column-reverse;
  }
  body .free-review-page .free-review-wrapper .audit-popup .form-section {
    padding: 50px 30px !important;
  }
  body .free-review-page .free-review-wrapper .audit-popup .image-section {
    width: unset !important;
    margin-right: 0 !important;
  }
  body .free-review-page .free-review-wrapper .audit-popup .image-section img {
    width: 100%;
  }
  .nav_wrapper .nav_links {
    display: none;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #0c0125, #070114);
    box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
    -webkit-box-shadow: 9px 15px 20px 0px rgba(24, 0, 77, 0.75);
    -moz-box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
    position: absolute;
    top: 85px;
    text-align: center;
    left: 0;
    width: 100%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: opacity 400ms ease-out, transform 400ms ease-out;
    opacity: 0;
    padding: 30px 0;
  }
  .nav_wrapper .nav_links.show {
    display: flex;
    transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateY(0);
    opacity: 1;
  }
  .nav_wrapper .nav_links .nav_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
  .nav_wrapper .nav_links li {
    padding-right: 0 !important;
    padding-bottom: 20px;
  }
  .nav_wrapper .nav_cta {
    display: flex;
    align-items: center;
  }
  .nav_wrapper .nav_cta .navbtn {
    margin-right: 14px;
  }
  #all.open {
    transform: translateX(-320px);
  }
  #all {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .mobile_menu {
    z-index: 1000;
    display: block !important;
  }
  #home_page .home_page_wrapper .hero_section .hero_content .hero_title,
  .conversion_catalyst_page .home_page_wrapper .hero_section .hero_content .hero_title {
    font-size: 38px !important;
    line-height: 1.1;
  }
  #home_page .home_page_wrapper .call_to_action_div,
  .conversion_catalyst_page .home_page_wrapper .call_to_action_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #home_page .home_page_wrapper .call_to_action_div .cta_question,
  .conversion_catalyst_page .home_page_wrapper .call_to_action_div .cta_question {
    margin-bottom: 30px;
  }
  #home_page .home_page_wrapper .call_to_action_div .waitlistcta,
  .conversion_catalyst_page .home_page_wrapper .call_to_action_div .waitlistcta {
    margin-top: 20px !important;
    text-align: center !important;
  }
  #home_page .home_page_wrapper .services .service_card,
  .conversion_catalyst_page .home_page_wrapper .services .service_card {
    margin-bottom: 28px !important;
  }
  #home_page .home_page_wrapper .packages_div .package_card,
  .conversion_catalyst_page .home_page_wrapper .packages_div .package_card {
    margin-bottom: 28px !important;
  }
  #home_page .home_page_wrapper .packages_div .package_card.customized_package,
  .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package {
    max-width: 320px !important;
    margin-top: 0 !important;
    display: flex;
    flex-direction: column !important;
  }
  #home_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header,
  .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header {
    text-align: center !important;
  }
  #home_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_subtitle,
  .conversion_catalyst_page .home_page_wrapper .packages_div .package_card.customized_package .package_card_header .package_subtitle {
    margin-bottom: 20px !important;
  }
  #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev,
  #home_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next,
  .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-prev,
  .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .swiper-button-next {
    display: none !important;
  }
  #home_page .home_page_wrapper .projects_div .projects_swiper .project_image,
  .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_image {
    margin: auto !important;
    float: none !important;
  }
  #home_page .home_page_wrapper .projects_div .projects_swiper .project_description,
  .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description {
    text-align: center;
    margin-left: 0 !important;
  }
  #home_page .home_page_wrapper .projects_div .projects_swiper .project_description .about_project_description,
  .conversion_catalyst_page .home_page_wrapper .projects_div .projects_swiper .project_description .about_project_description {
    margin: 23px auto !important;
  }
  #home_page .home_page_wrapper .testimonials_div .testimonial_card,
  .conversion_catalyst_page .home_page_wrapper .testimonials_div .testimonial_card {
    margin-bottom: 26px !important;
  }
  #home_page .home_page_wrapper .blogs_div .blog_card,
  .conversion_catalyst_page .home_page_wrapper .blogs_div .blog_card {
    margin-bottom: 26px !important;
  }
  #home_page .home_page_wrapper #footer .footer_wrapper,
  .conversion_catalyst_page .home_page_wrapper #footer .footer_wrapper {
    flex-direction: column;
    align-items: center;
  }
  #home_page .home_page_wrapper #footer .footer_links .footer_list,
  .conversion_catalyst_page .home_page_wrapper #footer .footer_links .footer_list {
    padding-left: 0;
    flex-direction: column;
    margin: 50px 0 30px;
  }
  #home_page .home_page_wrapper #footer .footer_links .footer_list .link_item li,
  .conversion_catalyst_page .home_page_wrapper #footer .footer_links .footer_list .link_item li {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  #home_page .home_page_wrapper #footer .social_icons,
  .conversion_catalyst_page .home_page_wrapper #footer .social_icons {
    margin-left: 20px !important;
    margin-right: 0px !important;
  }
}/*# sourceMappingURL=styles.css.map */