@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
$dark-blue: #0f0039;
$purple: #550df4;
$light-blue: #cde0ff;
$Turquoise: #02c6c3;
$Brandeis-Blue: #006bff;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
}
body {
  font-family: "Roboto", sans-serif;
  &.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: -3px -1px 20px 10000px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%;
    margin: 0 20px;
    max-width: 550px;
    height: 300px;
    border-radius: 20px;
    z-index: 9999;
  }

  .popup-content {
    font-size: 25px;
    text-align: center;
    padding: 10px;
  }

  .Header_page {
    height: 80px;
    width: 100%;
    z-index: 1000;
    position: absolute;
    .nav_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      .logo_div {
        margin-right: 20px;
        img {
          max-height: 40px;
        }
      }

      .nav_links {
        .nav_list {
          list-style: none;
          display: flex;
          margin-bottom: 0;
          padding-left: 0;
          .nav_link {
            text-decoration: none;
            color: #fff;
            li {
              margin: 0 15px;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
    .nav_cta {
      .mobile_menu {
        display: none;
      }
      button.navbtn {
        background-color: $purple;
        border: none;
        font-size: 15px;
        color: white;
        border-radius: 50px;
        padding: 8px 45px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .conversion_catalyst_page {
    background-color: #161616 !important;

    .home_page_wrapper {
      .hero_section {
        // background: url("..//assets/images/hero-section-grid.svg");
        // background-repeat:no-repeat;
        // background-size:cover;

        .hero_content {
          .sub-title {
            border: 1px solid grey;
            border-radius: 50px;
            max-width: 300px;
            margin: 3px auto 25px;
            background-color: transparent;
            padding: 6px 17px;
            p {
              font-weight: 300;
              font-size: 13px;
              margin-bottom: 0;
            }
          }
          .hero_title {
            font-weight: 600 !important;
            // line-height:77px!important;
            max-width: 830px !important;
            .purple_word {
              color: $purple;
            }
          }
          .button-caption {
            font-size: 13px;
            font-weight: 200px;
            margin-top: 5px;
          }
        }
      }
      .vsl {
        padding: 50px 0;
        .static_vsl {
          img {
            border-radius: 12px;
            width: 100%;
          }
        }
      }
      .video_testimonials {
        background-color: white;
        padding: 70px 0;
        .services_content {
          margin: 0 auto 60px;
          .services_title {
            max-width: 430px;
            font-size: 44px;
            margin: auto;
            text-align: center;
            .purple_color {
              color: $purple;
            }
          }
        }
        .videotestimonial_card {
          background: url("..//assets//images//grid2.svg"), #e2e6ec;
          border-radius: 20px;
          box-shadow: 0px 1px 14px 0px rgb(123 121 121 / 53%);
          -webkit-box-shadow: 0px 1px 14px 0px rgb(123 121 121 / 53%);
          -moz-box-shadow: 0px 1px 14px 0px rgb(123 121 121 / 53%);
          padding: 40px 15px;
        }
      }
      .text {
        text-align: center;
        .text_testimonial {
          max-width: 424px;
          margin: 0 auto 30px;
          font-size: 18px;
          font-weight: 500;
        }
        .text_box {
          background-color: white;
          border-radius: 10px;
          margin: auto;
          width: 241px;
          padding: 9px;
          .text_speaker {
            margin-bottom: 0;
            font-weight: 500;
          }
          .text_speaker_position {
            font-size: small;
            margin-bottom: 0;
          }
        }
      }
      .funnelQuestions {
        padding: 70px 0 0;
        .services_content {
          text-align: center;
          margin-bottom: 20px;
          .services_title {
            margin: auto;
            color: white;
            .purple_color {
              color: $purple;
            }
          }
        }
        .growth-accelerator {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100% !important;
          width: 100%;
          .growth-card {
            box-shadow: none !important;
          }
        }
      }
      .book_a_call {
        padding: 70px 20px 40px;
        .services_content {
          text-align: center;
          margin-bottom: 20px;
          .services_title {
            margin: auto;
            color: white;
            .purple_color {
              color: $purple;
            }
          }
        }
        .calendly {
          min-width: 320px;
          height: 700px;
        }
      }
    }
  }
  #home_page,
  .conversion_catalyst_page {
    background-color: #161616 !important;
    height: 100vh;
    .home_page_wrapper {
      background-image: radial-gradient(
        circle at 50% -1%,
        rgba(30, 4, 88, 0.55),
        #000 7%
      );

      .hero_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        height: 100vh;
        .hero_content {
          padding-top: 70px;
          .hero_title {
            font-weight: 500;
            font-size: 70px;
            max-width: 650px;
            // line-height: 1.1;
            margin: auto;
          }
          .hero_description {
            color: #fcfdff;
            max-width: 650px;
            font-size: 18px;
            font-weight: 300;
            padding: 40px 10px;
            margin: auto;
          }

          .herobtn {
            padding: 5.5px 4px 5.5px 18px !important;
            &:hover {
              img {
                transform: rotate(90deg);
              }
            }
            img {
              margin-left: 10px;
              width: 33px;
              transition: transform 200ms ease-in;
            }
          }
        }
      }
      .full_line {
        background: radial-gradient(
          48000.02% 50% at 50% 50%,
          #02c6c3 0%,
          #0f0039 33%,
          rgba(0, 107, 255, 0) 100%
        );
        height: 4px;
        width: 100%;
      }
      .half_line {
        background: url("..//assets/images/halfline.png");

        height: 2px;
        width: 50%;
        margin: auto;
      }
      .call_to_action_div {
        padding: 30px 0;
        .cta_question {
          font-size: 38px;
          font-weight: 500;
          color: white;
          margin: auto;
        }
        .waitlistcta {
          margin: auto;
          text-align: right;
          .cta_divbtn {
            padding: 7px 22px;
          }
        }
      }
      .cta {
        background-color: $purple;
        border: none;
        font-size: 16px;
        color: white;
        border-radius: 50px;
        padding: 6px 14px;
        &:hover {
          opacity: 0.8;
        }
        // width: 170px;
      }
      .services {
        padding: 80px 0 100px;
        .services_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .services_title {
            font-size: 44px;
            margin-bottom: 0;
          }
          .services_subtitle {
            color: grey;
          }
        }
        .service_card {
          border: 1px solid grey;
          border-radius: 10px;
          text-align: center;
          height: 280px;
          max-width: 310px;
          margin: auto;
          padding: 30px 15px;
          box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          .service_icon {
            width: 40px;
          }
          .service_name {
            color: white;
            margin-top: 14px;
          }
          .service_description {
            color: rgb(177, 177, 177);
            max-width: 300px;
            margin: 16px auto 0;
          }
        }
      }

      .work_process {
        padding: 70px 0;
        text-align: center;
        .work_process_title {
          margin-bottom: 0;
          font-size: 44px;
          color: white;
        }
        .work_process_subtitle {
          color: grey;
        }
        .process {
          margin-top: 70px;
          .process_line {
            align-items: center;
            text-align: center;
            width: 0.125rem;
            height: 99%;
            z-index: 1;
            right: 50%;
            border: 0.0625rem solid #fff;
            display: flex;
            justify-content: center;
            position: absolute;
          }
          .process_card {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 100px;
            z-index: 100;
            position: relative;
            .details_wrapper {
              .process_name {
                display: none;
              }
            }
            .process_name {
              color: $light-blue;
              width: 100%;
              font-size: 26px;
              max-width: 300px;
              text-align: center;
            }
            .process_number_wrapper {
              background-color: #fff;
              padding: 10px 14px;
              box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
              -webkit-box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
              -moz-box-shadow: 2px 0px 22px 10px rgba(15, 0, 57, 0.75);
              border-radius: 6px;

              .process_number {
                color: #000;
                font-size: 20px;
                font-weight: 500;
              }
            }
            .process_description {
              max-width: 300px;
              font-size: 18px;
              font-weight: 300;
              color: rgb(177, 177, 177);
            }
          }
        }
      }
      .packages_div {
        padding: 70px 0;
        .packages_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .packages_title {
            font-size: 44px;
            margin-bottom: 0;
          }
          .packages_subtitle {
            color: grey;
          }
        }
        .package_card {
          border: 1px solid grey;
          border-radius: 10px;
          max-width: 320px;
          margin: auto;
          padding: 13px 15px 20px;
          box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          .package_card_header {
            text-align: center;
            background-color: #f9f9f9;
            padding: 8px 10px;
            border-radius: 10px;
            margin-bottom: 20px;
            .package_title {
              color: black;
              font-size: 19px;
              margin-bottom: 0;
              &.customized {
                color: white;
              }
            }
            .package_subtitle {
              color: rgb(107, 107, 107);
              margin-bottom: 0;
              &.customized_p {
                color: rgb(177, 177, 177);
              }
            }
          }
          .packags_card_body {
            .package_list {
              margin-left: 0;
              padding-left: 0;
              ol {
                li {
                  margin-bottom: 10px;
                  color: rgb(177, 177, 177);
                }
              }
            }
          }
          .packages_card_footer {
            text-align: center;
            margin-top: 25px;
            .reqBtn {
              width: 100%;
              // padding: 2.5px 4px 2.5px 18px !important;
              position: relative;
              img {
                width: 30px;
                position: absolute;
                right: 3px;
                top: 3px;
              }
            }
          }
          &.customized_package {
            .package_card_header {
              text-align: left;
              margin-bottom: 0;
              background-color: transparent;
              .package_title {
                font-size: 25px;
              }
              .package_subtitle {
                font-size: 15px;
                margin-top: 4px;
              }
            }
            margin-top: 30px;
            max-width: 1082px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .reqBtn {
              padding: 7px 55px;
            }
          }
        }
      }

      .projects_div {
        padding: 70px 0;
        .projects_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .projects_title {
            font-size: 44px;
            margin-bottom: 0;
          }
          .projects_subtitle {
            color: grey;
          }
        }
        .projects_swiper {
          margin: 40px 0;
          .swiper-button-prev,
          .swiper-button-next {
            color: $light-blue;
          }
          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 20px;
            font-weight: 900;
          }
          .project_image {
            float: right;
            max-width: 451px;
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .project_description {
            margin-top: 10px;
            margin-left: 30px;
            .project_name {
              font-size: 25px;
              font-weight: 500;
              color: #fff;
            }
            .about_project_description {
              font-size: 16px;
              max-width: 450px;
              color: rgb(177, 177, 177);
              margin: 20px 0;
              font-weight: 300;
            }
            .project_link {
              border-radius: 50px;
              padding: 2.5px 4px 2.5px 18px !important;

              img {
                transform: rotate(-28deg);
                margin-left: 10px;
                width: 33px;
              }
            }
          }
          .mySlides {
            display: none;
          }
        }
      }

      .testimonials_div {
        padding: 70px 0;
        .testimonials_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .testimonials_title {
            font-size: 44px;
            margin-bottom: 0;
          }
          .testimonials_subtitle {
            color: grey;
          }
        }
        .testimonial_card {
          border: 1px solid grey;
          border-radius: 10px;
          height: 380px;
          max-width: 320px;
          margin: auto;
          padding: 33px 15px 20px;
          box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          .testimonial_card_content {
            .testimonial_card_title {
              color: white;
            }
            .testimonial_card_text {
              padding-top: 15px;
              color: rgb(177, 177, 177);
              font-size: 15px;
            }
          }
          .testimonial_img {
            width: 100%;
            max-width: 58px;
          }
          .closing_info {
            margin-top: 20px;
            .client_name {
              font-size: 16px;
              color: #fff;
              margin-bottom: 0;
            }
            .client_position {
              color: grey;
              font-size: 14px;
            }
          }
        }
      }

      .blogs_div {
        padding: 70px 0;
        .blogs_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .blogs_title {
            font-size: 44px;
            margin-bottom: 0;
          }
          .blogs_subtitle {
            color: grey;
          }
        }
        .blog_card {
          border: 1px solid grey;
          border-radius: 10px;

          max-width: 320px;
          margin: auto;
          padding: 15px;
          box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          .blog_thumbnail {
            width: 100%;
            max-width: fit-content;
          }
          .blog_title {
            color: rgb(230, 230, 230);
            margin-top: 20px;
            font-size: 21px;
            line-height: 1.1;
          }
          .blog_info {
            margin-top: 36px;
            .blog_author {
              font-size: 15px;
              color: #d6d6d6;
              margin-bottom: 0;
            }
            .blog_date {
              font-size: 14px;
              color: grey;
              margin-bottom: 10px;
            }
          }
        }
      }

      .faq_div {
        padding: 70px 0;
        .faq_content {
          text-align: center;
          color: white;
          margin-bottom: 40px;
          .faq_title {
            max-width: 340px;
            margin: auto;
            font-size: 44px;
            margin-bottom: 10px !important;
            line-height: 1;
          }
          .faq_subtitle {
            max-width: 450px;
            margin: auto;
            color: grey;
          }
        }
        .faq_card {
          border: 1px solid grey;
          border-radius: 10px;
          width: 100%;
          margin: 5px auto 26px;
          padding: 22px 15px;
          box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -webkit-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          -moz-box-shadow: 2px 0px 22px 0px rgba(15, 0, 57, 0.75);
          .faq_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .faq_header_title {
              margin-bottom: 0;
              font-weight: 400;
              font-size: 19px;
              color: rgb(250, 250, 250);
            }

            .faq_arrow {
              width: 32px;
              &.open {
                transform: rotate(180deg);
              }
            }
          }
          .faq_answer {
            margin-top: 20px;
            color: #c7c7c7;
          }
        }
      }
      #footer {
        padding: 26px 0 0px;
        .footer_wrapper {
          display: flex;
          justify-content: space-between;
        }
        .footer_slogan {
          text-align: center;
          color: white;
          font-size: 24px;
          margin-bottom: 50px;
        }
        .logo_div_footer {
          img {
            height: 35px;
          }
        }
        .footer_links {
          .footer_list {
            display: flex;
            align-items: center;
            list-style: none;
            .link_item {
              color: rgb(211, 211, 211);
              font-size: 14px;
              font-weight: 400;
              text-decoration: none;
              li {
                margin-right: 45px;
              }
            }
          }
        }
        .footer_cta {
          .footerbtn {
            padding: 5px 30px;
          }
        }
        .social_icons {
          text-align: center;
          margin: 40px auto 30px;
          a {
            margin-right: 20px;
          }
        }
        .rights {
          background-color: #060606;
          text-align: center;
          padding: 9px;
          .copyrights {
            font-size: 13px;
            color: grey;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  #newsletter_page {
    background-color: #000;
    height: 100vh;
    position: relative;
    .thank-you-popup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 450px !important;
      text-align: center;
      padding: 40px 20px !important;
      background: #ffffff;
      border-radius: 8px;
      margin: 20px auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .thank-you-btn {
        background-color: $purple;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        margin-top: 10px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .newsletter_wrapper {
      background-image: radial-gradient(
        circle at 50% -1%,
        rgba(30, 4, 88, 0.55),
        #000 7%
      );
      height: 100vh;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      .newsletter_logo {
        img {
          height: 40px;
        }
        text-align: center;
        padding: 23px 0 30px;
      }
      .page_content {
        .page_form {
          background-color: #fff;
          border-radius: 10px;
          padding: 40px 20px 30px;
          text-align: center;
          margin: 40px auto;
          .newsletter_title {
            color: #15171d;
            font-size: 40px;
            margin: 0px auto 20px;
            line-height: 1.1;
          }
          .newsletter_description {
            margin-bottom: 30px;
            font-size: 15px;
          }
          .newsletter_Form {
            text-align: left !important;

            form {
              flex-direction: column;
              input {
                padding: 8px 14px;
                // margin-bottom: 15px;
                &::placeholder {
                  font-size: 13px;
                }
              }
              .subscribeBtn {
                background-color: $purple;
                border: none;
                font-size: 16px;
                text-transform: uppercase;
                color: white;
                border-radius: 5px;
                padding: 8px 14px;
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
          .form_note {
            text-align: center;
            font-size: 14px;
            margin: 20px auto 10px;
            color: #494949;
          }
        }
      }
      // .values_list_div {
      //   margin: 30px auto 40px;
      //   .values_list {
      //     list-style: none;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     li {
      //       display: flex;
      //       align-items: center;
      //       p {
      //         margin-bottom: 0;
      //         margin-left: 10px;
      //         color: hsla(240, 7%, 62%, 1);
      //         font-weight: 300;
      //         font-size: 15px;
      //       }
      //     }
      //   }
      // }
    }
    .newsletter_footer {
      background-color: #060606;
      text-align: center;
      width: 100%;
      padding: 9px;
      .copyrights {
        font-size: 13px;
        color: grey;
        margin-bottom: 0;
      }
    }
  }

  .growth-accelerator {
    ul {
      padding-left: 0;
      margin-bottom: 0;
    }
    background-color: #000;
    height: 100vh;

    overflow-y: hidden;
    .growth_logo {
      img {
        height: 40px;
      }
      text-align: center;
      padding: 23px 0 30px;
    }
    .growth-accelerator-title {
      text-align: center;
      color: white;
      padding: 40px 0 20px;
      font-size: 30px;
      font-weight: 800;
      span {
        color: $purple;
      }
    }
    .growth-card {
      width: 100%;
      box-shadow: 0 10px 50px 16.6666666667px rgba(139, 139, 139, 0.12);
      border-radius: 15px;
      background-color: #fff;
      margin-bottom: 104px;
      margin-top: 30px;
      .card-content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0 0;

        .card_question {
          text-align: center;
        }

        .card-list {
          list-style: none;
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          cursor: pointer;
          li {
            border: 1px solid gray;
            border-radius: 8px;
            padding: 13px 20px;
            flex-basis: 45.3%;
            max-width: 300px;
            display: flex;
            font-size: 17px;
            align-items: center;
            margin-top: 32px;

            &.selected-answer {
              .radioBtn {
                background-color: $purple;
              }
            }
            &.index-zero {
              flex-basis: 63.3%;
            }
            .radioBtn {
              width: 18px;
              height: 18px;
              margin-right: 10px;
              border-radius: 50px;
              border: 1px solid $purple;
            }
          }
        }
        .text_area {
          width: 85%;
          margin-top: 30px;
          padding: 10px;
          border-radius: 10px;
          cursor: pointer;
          &::placeholder {
            font-weight: 300;
          }
        }
        .email_field {
          width: 100%;
          margin-top: 20px;
          padding: 8px 15px;
          border: 1px solid gray;
          outline: none;
          border-radius: 20px;
          cursor: pointer;
          max-width: 300px;
          &::placeholder {
            font-weight: 300;
          }
        }
        .card-bottom {
          width: 100%;
          display: flex;
          margin-top: 45px;
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
          align-items: flex-end;
          justify-content: space-between;
          position: relative;
          background-color: $purple;
          .cardBtn {
            color: white;
            background-color: transparent;
            border: none;
            outline: none;

            padding: 10px 20px;
            &:disabled {
              opacity: 0.5;
            }
            &.nextBtn {
              background: url("..//assets/images/right-arrow.png");
              background-position: 90% center;
              background-size: 20%;
              background-repeat: no-repeat;
              margin-right: 17px;
              max-width: 105px;
              width: 100%;
            }
            &.prevBtn {
              background: url("..//assets/images/left-arrow.png");
              background-position: -1% center;
              background-size: 20%;
              background-repeat: no-repeat;
              max-width: 117px;
              width: 100%;
              margin-left: 23px;
            }
          }
        }
      }
    }
  }
  .contactUs_page {
    .contactus_header {
      background-color: #000;
      height: 50vh;
      text-align: center;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .contactUs_title {
        color: $purple;
        // background: linear-gradient(
        //   45deg,
        //   #6f00ff 10%,
        //   #6f00ff 36%,
        //   #6f00ff 47%,
        //   #30b6ff 67%,
        //   #00f0ff 76%,
        //   #1e43ff
        // );
        // background-clip: text;
        // background-size: 100%;
        // background-repeat: repeat;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        font-size: 40px;
        font-weight: 500;
      }
    }
    .contact-us {
      padding: 50px 0;
      .contact-us-wrapper {
        margin: auto;
        box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
        -webkit-box-shadow: 9px 15px 20px 0px rgba(24, 0, 77, 0.75);
        -moz-box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
        border-radius: 10px;
        padding: 40px 10px;
      }
      .sendBtn {
        background-color: $purple;
        border: none;
        font-size: 17px;
        text-transform: uppercase;
        color: white;
        border-radius: 7px;
        padding: 6px 14px;
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .free-review-page {
    background-color: #000;
    padding-bottom: 30px;
    // height: 100vh;
    .free-review-wrapper {
      background-image: radial-gradient(
        circle at 50% -1%,
        rgba(30, 4, 88, 0.55),
        #000 7%
      );
      .review-page-logo {
        img {
          height: 40px;
        }
        text-align: center;
        padding: 23px 0 30px;
      }

      .thank-you-popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 450px !important;
        text-align: center;
        height: 100vh;
        padding: 40px 20px !important;

        .thank-you-btn {
          background-color: $purple;
          color: #fff;
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          width: 100%;
          cursor: pointer;
          margin-top: 10px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .audit-popup,
      .thank-you-popup {
        background: #ffffff;
        // padding: 20px;
        border-radius: 8px;
        // width: 80%;
        max-width: 900px;
        margin: 40px auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .form-section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px;
          .form-section-description {
            margin-bottom: 20px;
            h2 {
              margin-bottom: 14px;
              font-size: 33px;
              font-weight: 600;
            }

            p {
              font-size: 15px;
              margin: 10px 0;
            }
          }
          .form-group {
            margin-bottom: 15px;

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;

              &:focus {
                border-color: #6c63ff;
                outline: none;
              }

              &.is-invalid {
                border-color: red;
              }
            }

            .invalid-feedback {
              color: red;
              font-size: 12px;
              text-align: left;
              margin-top: 5px;
            }
          }

          .btn {
            background-color: #1f0065;
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            width: 100%;
            cursor: pointer;
            margin-top: 20px;
            &:hover {
              opacity: 0.7;
            }
          }
          .note {
            margin-top: 15px;
            font-size: 12px;
          }
          .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            color: #333;
            font-size: 18px;

            &:hover {
              color: #6c63ff;
            }
          }
        }

        .image-section {
          background-color: #1f0065;
          padding: 20px;
          border-radius: 8px;
          margin-right: 15px;
          width: 400px;
          // margin: auto;
          img {
            // width: 300px;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &.hide {
      display: none;
    }
    .thank-you-popup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 450px !important;
      text-align: center;
      padding: 40px 20px !important;

      .thank-you-btn {
        background-color: $purple;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        margin-top: 10px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .audit-popup,
    .thank-you-popup {
      background: #ffffff;
      // padding: 20px;
      border-radius: 8px;
      width: 80%;
      max-width: 900px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .form-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        .form-section-description {
          margin-bottom: 20px;
          h2 {
            margin-bottom: 14px;
            font-size: 33px;
            font-weight: 600;
          }

          p {
            font-size: 15px;
            margin: 10px 0;
          }
        }
        .form-group {
          margin-bottom: 15px;

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;

            &:focus {
              border-color: #6c63ff;
              outline: none;
            }

            &.is-invalid {
              border-color: red;
            }
          }

          .invalid-feedback {
            color: red;
            font-size: 12px;
            text-align: left;
            margin-top: 5px;
          }
        }

        .btn {
          background-color: $dark-blue;
          color: #fff;
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          width: 100%;
          cursor: pointer;
          margin-top: 20px;
          &:hover {
            opacity: 0.7;
          }
        }
        .note {
          margin-top: 15px;
          font-size: 12px;
        }
        .close-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          color: #333;
          font-size: 18px;

          &:hover {
            color: #6c63ff;
          }
        }
      }

      .image-section {
        background-color: #0f0039;
        padding: 20px;
        border-radius: 8px;
        margin-right: 15px;
        width: 400px;
        // margin: auto;
        img {
          // width: 300px;
          border-radius: 8px;
        }
      }
    }
  }
}
@media (max-width: 1049px) {
  body {
    .popup-container {
      .audit-popup {
        .form-section {
          padding: 35px 25px !important;
        }
        .image-section {
          display: none !important;
        }
      }
    }
  }
}
@media (min-width: 440px) and (max-width: 539px) {
  .work_process {
    .process_line {
      left: 3.4625rem !important;
    }
  }
}
@media (max-width: 420px) {
  .work_process {
    .process_line {
      height: 78% !important;
      top: 0px;
    }
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .work_process {
    .process_line {
      left: 5.3625rem !important;
    }
  }
}
@media (max-width: 768px) {
  // .vsl {
  //   .static_vsl {
  //     img {
  //       height: 300px;
  //     }
  //   }
  // }
  .work_process {
    .process {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      position: relative;
      .process_card {
        justify-content: unset !important;
      }
    }
    .process_line {
      left: 1.5625rem;
      height: 92%;
      right: 0 !important;
    }
    .details_wrapper {
      text-align: left;
      margin-left: 41px;
      .process_name {
        text-align: left !important;
        display: block !important;
      }
    }
    .process_name {
      display: none;
    }
  }
}
@media (max-width: 990px) {
  body {
    .free-review-page {
      .free-review-wrapper {
        .audit-popup {
          flex-direction: column-reverse;
          .form-section {
            padding: 50px 30px !important;
          }
          .image-section {
            width: unset !important;
            margin-right: 0 !important;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .nav_wrapper {
    .nav_links {
      display: none;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(to bottom, #0c0125, #070114);
      box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
      -webkit-box-shadow: 9px 15px 20px 0px rgba(24, 0, 77, 0.75);
      -moz-box-shadow: 3px 1px 20px 0px rgba(24, 0, 77, 0.75);
      position: absolute;
      top: 85px;
      text-align: center;
      left: 0;
      width: 100%;
      z-index: 1000;
      transform: translateY(-100%);
      transition: opacity 400ms ease-out, transform 400ms ease-out;
      opacity: 0;
      padding: 30px 0;

      &.show {
        display: flex;
        transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
        transform: translateY(0);
        opacity: 1;
      }
      .nav_list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
      }
      li {
        padding-right: 0 !important;
        padding-bottom: 20px;
      }
    }

    .nav_cta {
      display: flex;
      align-items: center;
      .navbtn {
        margin-right: 14px;
      }
    }
  }
  #all.open {
    transform: translateX(-320px);
  }

  #all {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .mobile_menu {
    z-index: 1000;
    display: block !important;
  }
  #home_page,
  .conversion_catalyst_page {
    .home_page_wrapper {
      .hero_section {
        .hero_content {
          .hero_title {
            font-size: 38px !important;
            line-height:1.1;
          }
        }
      }
      .call_to_action_div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cta_question {
          margin-bottom: 30px;
        }
        .waitlistcta {
          margin-top: 20px !important;
          text-align: center !important;
        }
      }
      .services {
        .service_card {
          margin-bottom: 28px !important;
        }
      }

      .packages_div {
        .package_card {
          margin-bottom: 28px !important;
        }

        .package_card.customized_package {
          max-width: 320px !important;
          margin-top: 0 !important;
          display: flex;
          flex-direction: column !important;
          .package_card_header {
            text-align: center !important;
            .package_subtitle {
              margin-bottom: 20px !important;
            }
          }
        }
      }
      .projects_div {
        .projects_swiper {
          .swiper-button-prev,
          .swiper-button-next {
            display: none !important;
          }
          .project_image {
            margin: auto !important;
            float: none !important;
          }
          .project_description {
            text-align: center;
            margin-left: 0 !important;
            .about_project_description {
              margin: 23px auto !important;
            }
          }
        }
      }
      .testimonials_div {
        .testimonial_card {
          margin-bottom: 26px !important;
        }
      }
      .blogs_div {
        .blog_card {
          margin-bottom: 26px !important;
        }
      }
      #footer {
        .footer_wrapper {
          flex-direction: column;
          align-items: center;
        }
        .footer_links {
          .footer_list {
            padding-left: 0;
            flex-direction: column;
            margin: 50px 0 30px;
            .link_item {
              li {
                margin-right: 0 !important;
                margin-bottom: 15px;
              }
            }
          }
        }
        .social_icons {
          margin-left: 20px !important;
          margin-right: 0px !important;
        }
      }
    }
  }
}
